<template>
  <div>
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :layout="layout"
      :label-align="labelAlign"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-row :gutter="gutter">
        <a-col :span="24">
          <div class="common-title">基本信息</div>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="小组名称" prop="name">
            <a-input v-model="form.name" placeholder="输入小组名称（最多30字）" :maxLength="30" allowClear></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="小组组长" prop="group_leader">
            <role-select
              v-model="form.group_leader"
              :privilege="9"
              :props="{ placeholder: '请选择小组组长', allowClear: true }"
            ></role-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="值班组长（多选）">
            <role-select
              v-model="form.duty_team_leader"
              :privilege="10"
              :props="{ placeholder: '请选择值班组长', allowClear: true, mode: 'multiple' }"
            ></role-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="小组副组长（多选）">
            <role-select
              v-model="form.chargehand"
              :privilege="11"
              :props="{ placeholder: '请选择小组副组长', allowClear: true, mode: 'multiple' }"
            ></role-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="统计人员（多选）">
            <role-select
              v-model="form.statisticians"
              :privilege="13"
              :props="{ placeholder: '请选择统计人员', allowClear: true, mode: 'multiple' }"
            ></role-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="小组分类" prop="classify">
            <a-radio-group v-model="form.classify">
              <a-radio :value="item.value" v-for="item in groupOptions" :key="item.value">
                {{ item.label }}
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="是否有固定服务日" required validateStatus="success" class="mb-0">
            <a-form-model-item label="" prop="is_fixed" class="input-item">
              <a-radio-group v-model="form.is_fixed">
                <a-radio :value="item.value" v-for="item in flagOptions" :key="item.value">
                  {{ item.label }}
                </a-radio>
              </a-radio-group>
            </a-form-model-item>
            <a-form-model-item label="" prop="week" class="input-item" v-if="form.is_fixed == 1">
              <a-select
                placeholder="选择服务日"
                :options="weekOptions"
                v-model="form.week"
                allowClear
                style="width: 240px;"
              >
              </a-select>
            </a-form-model-item>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="是否为念佛团">
            <a-radio-group v-model="form.is_old">
              <a-radio :value="item.value" v-for="item in flagOptions" :key="item.value">
                {{ item.label }}
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
        </a-col>
        <a-col :span="24">
          <div class="common-title">
            志工面试条件
            <span class="title-tip">（即转实习期）</span>
          </div>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="注册时间">
            待面试状态需
            <a-select
              placeholder="请选择条件"
              :options="symbolOptions"
              v-model="form.interview_condition"
              allowClear
              class="number-input"
              style="vertical-align: top;"
            >
            </a-select>
            <a-input-number
              v-model="form.interview_min_month"
              placeholder="请输入数字"
              :min="1"
              :step="1"
              allowClear
              class="number-input"
              style="margin-left: 0;"
            ></a-input-number>
            <template v-if="form.interview_condition == 6">
              ~
              <a-input-number
                v-model="form.interview_max_month"
                placeholder="请输入数字"
                :min="1"
                :step="1"
                allowClear
                class="number-input"
              ></a-input-number>
            </template>
            个月
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="培训要求">
            课程培训类型为
            <a-select
              placeholder="请选择培训类型"
              :options="trainTypeOptions"
              v-model="form.interview_train_type_id"
              allowClear
              class="item-select"
            >
            </a-select>
            需评价通过
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="出勤次数">
            注册填表后
            <a-input-number
              v-model="form.registration_month"
              placeholder="请输入数字"
              :min="1"
              :step="1"
              allowClear
              class="number-input"
            ></a-input-number>
            个月内志工服务出勤次数达
            <a-input-number
              v-model="form.attendance_times"
              placeholder="请输入数字"
              :min="1"
              :step="1"
              allowClear
              class="number-input"
            ></a-input-number>
            次
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="若存在休眠激活情况">
            休眠激活之日起
            <a-input-number
              v-model="form.activation_month"
              placeholder="请输入数字"
              :min="1"
              :step="1"
              allowClear
              class="number-input"
            ></a-input-number>
            个月内志工服务出勤次数达
            <a-input-number
              v-model="form.activation_times"
              placeholder="请输入数字"
              :min="1"
              :step="1"
              allowClear
              class="number-input"
            ></a-input-number>
            次
          </a-form-model-item>
        </a-col>
        <a-col :span="24">
          <div class="common-title">
            志工转正条件
            <span class="title-tip">（即转正式）</span>
          </div>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="培训要求">
            课程培训类型为
            <a-select
              placeholder="请选择培训类型"
              :options="trainTypeOptions"
              v-model="form.formal_train_type_id"
              allowClear
              class="item-select"
            >
            </a-select>
            需评价通过
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="出勤次数">
            面试通过之日起
            <a-input-number
              v-model="form.formal_month"
              placeholder="请输入数字"
              :min="1"
              :step="1"
              allowClear
              class="number-input"
            ></a-input-number>
            个月内志工服务出勤次数达
            <a-input-number
              v-model="form.formal_times"
              placeholder="请输入数字"
              :min="1"
              :step="1"
              allowClear
              class="number-input"
            ></a-input-number>
            次
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="若存在休眠激活情况">
            休眠激活之日起
            <a-input-number
              v-model="form.formal_activation_month"
              placeholder="请输入数字"
              :min="1"
              :step="1"
              allowClear
              class="number-input"
            ></a-input-number>
            个月内志工服务出勤次数达
            <a-input-number
              v-model="form.formal_activation_times"
              placeholder="请输入数字"
              :min="1"
              :step="1"
              allowClear
              class="number-input"
            ></a-input-number>
            次
          </a-form-model-item>
        </a-col>
        <a-col :span="24">
          <div class="common-title">附加其他条件</div>
        </a-col>
        <a-col :span="24">
          <a-form-model-item label="" :label-col="halfLabelCol" :wrapper-col="halfWrapperCol">
            <rich-editor v-model="form.other_conditions" placeholder="若附加其他特殊转正条件，请备注"></rich-editor>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-col :span="12">
        <a-form-model-item label="排序">
          <a-input-number
            v-model="form.sort"
            placeholder="数字越小排位越前"
            allow-clear
            style="width: 100%;"
          ></a-input-number>
        </a-form-model-item>
      </a-col>
    </a-form-model>
  </div>
</template>

<script>
import { clone, dataConvertOptions, isEmpty } from "../../../../common/js/tool";
import { flagOptions, weekOptions, groupOptions, symbolOptions } from "../../../../common/hr/volunteer";

const multipleKeys = ["duty_team_leader", "chargehand", "statisticians"];

export default {
  name: "GroupForm",
  props: {
    initial: Object,
    halfLabelCol: Object,
    halfWrapperCol: Object,
    labelCol: Object,
    wrapperCol: Object,
    labelAlign: {
      type: String,
      default: "left",
    },
    layout: {
      type: String,
      default: "vertical",
    },
    gutter: {
      type: [Number, String, Array],
      default: 32,
    },
  },
  data() {
    return {
      form: {},
      rules: {
        name: [{ required: true, message: "请输入小组名称", trigger: "blur" }],
        group_leader: [{ required: true, message: "请选择小组组长", trigger: "change" }],
        classify: [{ required: true, message: "请选择小组类别", trigger: "change" }],
        is_fixed: [{ required: true, message: "请选择是否有固定服务日", trigger: "change" }],
        week: [{ required: true, message: "请选择服务日", trigger: "change" }],
      },
      flagOptions,
      groupOptions,
      volunteerOptions: [],
      trainTypeOptions: [],
      weekOptions,
      symbolOptions,
    };
  },
  watch: {
    initial: {
      handler() {
        this.init();
      },
      immediate: true,
    },
  },
  created() {
    this.getVolunteer();
    this.getTrainType();
    // this.init();
  },
  methods: {
    getVolunteer() {
      this.$axios({
        url: "/admin/volunteer",
        method: "GET",
        params: {
          sort: "-id",
          pageSize: 9999,
        },
      }).then((res) => {
        this.volunteerOptions = dataConvertOptions(res.data);
      });
    },
    getTrainType() {
      this.$axios({
        url: "/admin/volunteer-train-type",
        method: "GET",
        params: {
          sort: "-id",
          pageSize: 9999,
        },
      }).then((res) => {
        this.trainTypeOptions = dataConvertOptions(res.data);
      });
    },
    init() {
      let initial = this.initial;
      if (initial) {
        let form = clone(initial);
        multipleKeys.forEach((key) => {
          if (!isEmpty(form[key])) {
            form[key] = form[key].split(",");
          }
        });
        this.form = form;
      }
    },
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.ruleForm.validate((flag) => {
          if (flag) {
            let form = clone(this.form);
            multipleKeys.forEach((key) => {
              if (!isEmpty(form[key])) {
                form[key] = form[key].join(",");
              }
            });
            resolve(form);
          } else {
            reject();
          }
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.ant-radio-group {
  min-height: 32px;
  line-height: 32px;
}
.input-item {
  display: inline-block;
  padding-bottom: 0;
}
.special-item {
  display: inline-block;
  width: 100%;
  padding: 0;
}
.mb-0 {
  margin-bottom: 0;
}
.ant-input-number {
  width: 240px;
}
.common-title {
  font-size: 20px;
  margin-bottom: 12px;
  font-weight: 600;
}
.title-tip {
  font-size: 12px;
}

.number-input {
  width: 120px;
  margin: 0 12px;
}
.item-select {
  width: 240px;
  margin: 0 12px;
}
</style>
