<template>
  <div class="content md no-padding flex-box vertical">
    <div class="area vertical">
      <GroupForm ref="editForm" :initial="initial" v-show="edit" class="area-content"></GroupForm>
      <div class="area-content" v-if="detail" v-show="!edit">
        <Descriptions title="基本信息" :list="basicList" :detail="detail" class="mb-24"></Descriptions>
        <Descriptions title="志工面试条件" :list="interviewList" :detail="detail" class="mb-24"></Descriptions>
        <Descriptions title="志工转正条件" :list="formalList" :detail="detail" class="mb-24"></Descriptions>
        <Descriptions title="附加其他条件" :list="otherList" :detail="detail" class="mb-24"></Descriptions>
      </div>
    </div>
    <a-space class="footer">
      <template v-if="edit">
        <a-button @click="handleCancel">取消</a-button>
        <a-button type="primary" @click="handleSave">保存</a-button>
      </template>
      <template v-else>
        <a-button @click="back">返回</a-button>
        <a-button type="primary" @click="handleEdit">编辑</a-button>
      </template>
    </a-space>
  </div>
</template>

<script>
import { groupOptions, flagOptions, weekOptions, symbolOptions } from "../../../common/hr/volunteer";
import GroupForm from "./components/GroupForm.vue";
import Descriptions from "./components/Descriptions.vue";
import { isEmpty } from "../../../common/js/tool";

export default {
  name: "GroupDetail",
  components: {
    GroupForm,
    Descriptions,
  },
  data() {
    return {
      edit: false,
      detail: null,
      initial: null,
      basicList: [],
      interviewList: [],
      formalList: [],
      otherList: [],
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
  created() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      if (this.id) {
        this.$axios({
          url: `/admin/volunteer-group/${this.id}`,
          method: "GET",
          params: {
            expand: "groupLeader,dutyTeam,chargehandLeader,statisticiansLeader,interviewTrain,formalTrain",
          },
        }).then((res) => {
          this.detail = res;
          if (this.$route.query.edit) {
            this.handleEdit();
          } else {
            this.getDescriptionsList();
          }
        });
      }
    },
    getDescriptionsList() {
      let { is_fixed } = this.detail;
      let basicList = [];
      let interviewList = [];
      let formalList = [];
      let otherList = [];
      basicList.push(
        {
          key: "name",
          label: "小组名称",
        },
        {
          label: "小组组长",
          slot: (detail) => {
            return <open-data type="userName" openid={detail.group_leader} />;
          },
        },
        {
          key: "duty_team_leader",
          label: "值班组长",
          options: this.detail.duty_team_leader?.split(",").map((user_id) => ({ value: user_id, is_user_id: true })),
          tag: true,
        },
        {
          key: "chargehand",
          label: "小组副组长",
          options: this.detail.chargehand?.split(",").map((user_id) => ({ value: user_id, is_user_id: true })),
          tag: true,
        },
        {
          key: "statisticians",
          label: "统计人员",
          options: this.detail.statisticians?.split(",").map((user_id) => ({ value: user_id, is_user_id: true })),
          tag: true,
        },
        {
          key: "classify",
          label: "小组分类",
          options: groupOptions,
          select: true,
        },
        {
          key: "is_fixed",
          label: "是否有固定服务日",
          options: flagOptions,
          select: true,
        }
      );
      if (is_fixed == 1) {
        basicList.push({
          key: "week",
          label: "服务日",
          options: weekOptions,
          select: true,
        });
      }
      basicList.push({
        key: "sort",
        label: "排序",
      });
      interviewList.push(
        {
          label: "注册时间",
          slot: (detail) => {
            let { interview_condition, interview_min_month, interview_max_month } = detail;
            let res = <span>-</span>;
            if (interview_condition) {
              let target = symbolOptions.find((item) => item.value == detail.interview_condition);
              if (interview_condition == 6) {
                res = (
                  <span>
                    待面试状态需 {interview_min_month} ~ {interview_max_month} 个月
                  </span>
                );
              } else {
                res = (
                  <span>
                    待面试状态需 {target.label} {interview_min_month} 个月
                  </span>
                );
              }
            }
            return res;
          },
        },
        {
          label: "培训要求",
          slot: (detail) => {
            let { interviewTrain } = detail;
            if (interviewTrain?.name) {
              return <span>课程培训类型为 {interviewTrain.name} 需评价通过</span>;
            } else {
              return <span>-</span>;
            }
          },
        },
        {
          label: "出勤次数",
          slot: (detail) => {
            let { registration_month, attendance_times } = detail;
            if (!isEmpty(registration_month) && !isEmpty(attendance_times)) {
              return (
                <span>
                  注册填表后 {registration_month} 个月内志工服务出勤次数达 {attendance_times} 次
                </span>
              );
            } else {
              return <span>-</span>;
            }
          },
        },
        {
          label: "若存在休眠激活情况",
          slot: (detail) => {
            let { activation_month, activation_times } = detail;
            if (!isEmpty(activation_month) && !isEmpty(activation_times)) {
              return (
                <span>
                  休眠激活之日起 {activation_month} 个月内志工服务出勤次数达 {activation_times} 次
                </span>
              );
            } else {
              return <span>-</span>;
            }
          },
        }
      );
      formalList.push(
        {
          label: "培训要求",
          slot: (detail) => {
            let { formalTrain } = detail;
            if (formalTrain?.name) {
              return <span>课程培训类型为 {formalTrain.name} 需评价通过</span>;
            } else {
              return <span>-</span>;
            }
          },
        },
        {
          label: "出勤次数",
          slot: (detail) => {
            let { formal_month, formal_times } = detail;
            if (!isEmpty(formal_month) && !isEmpty(formal_times)) {
              return (
                <span>
                  面试通过之日起 {formal_month} 个月内志工服务出勤次数达 {formal_times} 次
                </span>
              );
            } else {
              return <span>-</span>;
            }
          },
        },
        {
          label: "若存在休眠激活情况",
          slot: (detail) => {
            let { formal_activation_month, formal_activation_times } = detail;
            if (!isEmpty(formal_activation_month) && !isEmpty(formal_activation_times)) {
              return (
                <span>
                  休眠激活之日起 {formal_activation_month} 个月内志工服务出勤次数达 {formal_activation_times} 次
                </span>
              );
            } else {
              return <span>-</span>;
            }
          },
        }
      );
      otherList.push({
        key: "other_conditions",
        label: "备注",
        rich: true,
      });
      this.basicList = basicList;
      this.interviewList = interviewList;
      this.formalList = formalList;
      this.otherList = otherList;
    },
    handleEdit() {
      this.edit = true;
      this.initial = this.detail;
    },
    handleSave() {
      this.$refs.editForm.validate().then((form) => {
        this.$axios({
          url: `/admin/volunteer-group/${this.id}`,
          method: "PATCH",
          data: form,
        }).then(() => {
          this.$message.success("保存成功");
          this.getDetail();
          this.handleCancel();
        });
      });
    },
    handleCancel() {
      if (this.$route.query.edit) {
        this.back();
      } else {
        this.edit = false;
        this.initial = null;
      }
    },
    back() {
      this.$router.back();
    },
  },
};
</script>

<style lang="less" scoped>
.footer {
  width: 100%;
}
.mb-24 {
  margin-bottom: 24px;
}
</style>
